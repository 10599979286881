import './style.scss';
import * as bootstrap from 'bootstrap'
import Google from 'ol/source/Google.js';
// import Layer from 'ol/layer/WebGLTile.js';
import {Circle as CircleStyle, Fill, Stroke, Style, Icon} from 'ol/style.js';
import {Draw, Modify, Snap} from 'ol/interaction.js';
import {GeometryCollection, Point, Polygon} from 'ol/geom.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {circular} from 'ol/geom/Polygon.js';
import {getDistance} from 'ol/sphere.js';
import {Map, View} from 'ol';
import {fromLonLat} from 'ol/proj.js';
import {Control, defaults as defaultControls} from 'ol/control.js';
import {transform} from 'ol/proj.js';
import ContextMenu from 'ol-contextmenu';
// import {DEVICE_PIXEL_RATIO} from 'ol/has.js';
// import Feature from 'ol/Feature.js';
// import {createBox} from 'ol/interaction/Draw.js';

const API_KEY = 'AIzaSyA4EG-U_ML5WaKHUoXmxJpGbiYhWIFl_7A';

let currentLink = document.location.href;

let formBlock = document.getElementsByClassName('form-block')[0];
let createCoordMapBtn = document.getElementById('submit-button');

let controlContainer = document.getElementById('control-container');
let mapContainer = document.getElementById('map-container');

const toastLive = document.getElementById('liveToast');

let historyWindow = document.getElementById('history-window');

let historyInfo = document.getElementById('history-info');
let featuresList = document.getElementById('features-list');
let featuresArray = [];
let resultArr = [];


// let currentMousePosition = document.getElementById('current-mouse');
// mapContainer.addEventListener("mousemove", (event) => {
//   let target = controlContainer.getBoundingClientRect();
// 	let x = event.clientX;
// 	let y = Math.round(event.clientY - target.height);
//   currentMousePosition.innerHTML = `${x}:${y}`;
// });

function showToastNow(heading, type = 'primary', text) {
  let toastLiveHeading = document.getElementById('toast-heading');
  let toastLiveText = document.getElementById('toast-body');

  toastLive.classList.add('text-bg-' + type);

  toastLiveHeading.innerHTML = heading;
  toastLiveText.innerHTML = text;

  const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLive);
  toastBootstrap.show()
}




function showMap(coords, zoom) {

  // console.log(coords);

  class GoogleLogoControl extends Control {
    constructor() {
      const element = document.createElement('img');
      element.style.pointerEvents = 'none';
      element.style.position = 'absolute';
      element.style.bottom = '5px';
      element.style.left = '5px';
      element.src =
        'https://developers.google.com/static/maps/documentation/images/google_on_white.png';
      super({
        element: element,
      });
    }
  }

  document.body.classList.remove('main-page');

  let mapEl = document.createElement('div');
  mapEl.classList.add('map');
  mapEl.id = 'map';
  mapContainer.append(mapEl);

  const raster = new TileLayer({
    source: new Google({
        key: API_KEY,
        scale: 'scaleFactor2x',
        highDpi: true,
        language: 'ru',
        mapType: 'satellite',
    })
  });

  function getFilteredFeaturesArray() {
    resultArr = featuresArray.reduce((acc, item) => {
      if (acc.includes(item)) {
        return acc; // если значение уже есть, то просто возвращаем аккумулятор
      }
      return [...acc, item]; // добавляем к аккумулятору и возвращаем новый аккумулятор
    }, []);
    return resultArr;
  }
  
  const source = new VectorSource();
  
  const vector = new VectorLayer({
    source: source,
    style: function (feature, resolution) {
      const geometry = feature.getGeometry();

      const name = geometry.get('name');
      console.log('name = ', name);
      
      console.log('feature[ol_uid] = ', feature['ol_uid']);
      featuresArray.push(feature['ol_uid']);

      // resultArr = featuresArray.reduce((acc, item) => {
      //   if (acc.includes(item)) {
      //     return acc; // если значение уже есть, то просто возвращаем аккумулятор
      //   }
      //   return [...acc, item]; // добавляем к аккумулятору и возвращаем новый аккумулятор
      // }, []);

      // console.log('resultArr = ', resultArr);

      let filteredArr = getFilteredFeaturesArray();
      console.log('filteredArr = ', filteredArr);

      // featuresElem.innerHTML = resultArr;
      refreshFeaturesList(filteredArr);

      let radius_px;
      let circleDot;

      if (name === 'suppression-zone' || name === 'all-devices' || name === 'detector' || name === 'suppressor' || name === 'detection-station' || name === 'suppression') {
        const geometries = geometry.getGeometries();
        const polygon = geometries[0].getCoordinates()[0];
        const center = geometries[1].getCoordinates();


        const center_px = map.getPixelFromCoordinate(center);
        const first_px = map.getPixelFromCoordinate(polygon[0]);

        radius_px = (center_px[1] - first_px[1]) * 2;
        circleDot = radius_px / 10;
        // let radius_px = Math.sqrt(Math.pow(first_px[0] - center_px[0], 2) + Math.pow(first_px[1] - center_px[1], 2));

        // console.log('center_px = ', center_px);
        // console.log('first_px = ', first_px);
        // console.log('radius_px = ', radius_px);
      }

      switch(name) {
        case 'detection':
          return new Style({
            geometry: function (feature) {
              return feature.get('modifyGeometry') || feature.getGeometry();
            },
            fill: new Fill({
              color: 'rgba(0, 174, 239, 0.2)',
            }),
            stroke: new Stroke({
              color: '#00AEEF',
              lineDash: [10, 15],
              lineCap: 'square',
              width: 3,
            }),
            image: new CircleStyle({
              radius: 7,
              fill: new Fill({
                color: 'rgba(0, 0, 0, 0)',
              }),
            }),
          })

        case 'detection-1-5':
          return new Style({
            geometry: function (feature) {
              return feature.get('modifyGeometry') || feature.getGeometry();
            },
            fill: new Fill({
              color: 'rgba(139, 0, 255, 0.2)',
            }),
            stroke: new Stroke({
              color: '#8B00FF',
              lineDash: [10, 15],
              lineCap: 'square',
              width: 3,
            }),
            image: new CircleStyle({
              radius: 7,
              fill: new Fill({
                color: 'rgba(0, 0, 0, 0)',
              }),
            }),
          })

        case 'radar':
          return new Style({
            geometry: function (feature) {
              return feature.get('modifyGeometry') || feature.getGeometry();
            },
            fill: new Fill({
              color: 'rgba(241, 184, 27, 0.4)',
            }),
            image: new CircleStyle({
              radius: 7,
              fill: new Fill({
                color: 'rgba(0, 0, 0, 0)',
              }),
            }),
          })

        case 'suppression':
          return new Style({
            geometry: function (feature) {
              return feature.get('modifyGeometry') || feature.getGeometry();
            },
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0)',
            }),
            image: new Icon({
              opacity: 1,
              src: '/assets/images/suppression-circle.svg',
              scale: radius_px,
            })
          })

          case 'detector':
            return new Style({
              geometry: function (feature) {
                return feature.get('modifyGeometry') || feature.getGeometry();
              },
              fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
              }),
              stroke: new Stroke({
                color: '#2AABE2',
                width: 5,
              }),
              image: new CircleStyle({
                radius: circleDot,
                fill: new Fill({
                  color: '#2AABE2',
                }),
              }),
            })

          case 'suppressor':
            return new Style({
              geometry: function (feature) {
                return feature.get('modifyGeometry') || feature.getGeometry();
              },
              fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
              }),
              stroke: new Stroke({
                color: '#EC1071',
                width: 5,
              }),
              image: new CircleStyle({
                radius: circleDot,
                fill: new Fill({
                  color: '#EC1071',
                }),
              }),
            })

          case 'detection-station':
            return new Style({
              geometry: function (feature) {
                return feature.get('modifyGeometry') || feature.getGeometry();
              },
              fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
              }),
              stroke: new Stroke({
                color: '#F1B81B',
                width: 5,
              }),
              image: new CircleStyle({
                radius: circleDot,
                fill: new Fill({
                  color: '#F1B81B',
                }),
              }),
            })

          case 'all-devices':
            return new Style({
              geometry: function (feature) {
                return feature.get('modifyGeometry') || feature.getGeometry();
              },
              fill: new Fill({
                color: 'rgba(255, 255, 255, 0)',
              }),
              image: new Icon({
                opacity: 1,
                src: '/assets/images/all-devices.svg',
                scale: radius_px,
              })
            })

        default:
          return new Style({
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)',
            }),
            stroke: new Stroke({
              color: '#00AEEF',
              width: 4,
            }),
            image: new CircleStyle({
              radius: 7,
              fill: new Fill({
                color: '#00AEEF',
              }),
            }),
          })
      }



    },
  });

  const defaultStyle = new Modify({source: source})
  .getOverlay()
  .getStyleFunction();

  const modify = new Modify({
    source: source,
    style: function (feature) {
      feature.get('features').forEach(function (modifyFeature) {
        const modifyGeometry = modifyFeature.get('modifyGeometry');
        if (modifyGeometry) {
          const modifyPoint = feature.getGeometry().getCoordinates();
          const geometries = modifyFeature.getGeometry().getGeometries();
          const polygon = geometries[0].getCoordinates()[0];
          const center = geometries[1].getCoordinates();
          const projection = map.getView().getProjection();
          let first, last, radius;
          if (modifyPoint[0] === center[0] && modifyPoint[1] === center[1]) {
            // center is being modified
            // get unchanged radius from diameter between polygon vertices
            first = transform(polygon[0], projection, 'EPSG:4326');
            last = transform(
              polygon[(polygon.length - 1) / 2],
              projection,
              'EPSG:4326',
            );
            radius = getDistance(first, last) / 2;
          } else {
            // radius is being modified
            first = transform(center, projection, 'EPSG:4326');
            last = transform(modifyPoint, projection, 'EPSG:4326');
            radius = getDistance(first, last);
          }
          // update the polygon using new center or radius
          const circle = circular(
            transform(center, projection, 'EPSG:4326'),
            radius,
            128,
          );
          circle.transform('EPSG:4326', projection);
          geometries[0].setCoordinates(circle.getCoordinates());
          // save changes to be applied at the end of the interaction
          modifyGeometry.setGeometries(geometries);
        }
      });
      return defaultStyle(feature);
    },
  });

  modify.on('modifystart', function (event) {
    event.features.forEach(function (feature) {
      const geometry = feature.getGeometry();
      if (geometry.getType() === 'GeometryCollection') {
        feature.set('modifyGeometry', geometry.clone(), true);
      }
    });
  });

  modify.on('modifyend', function (event) {
    event.features.forEach(function (feature) {
      const modifyGeometry = feature.get('modifyGeometry');
      if (modifyGeometry) {
        feature.setGeometry(modifyGeometry);
        feature.unset('modifyGeometry', true);
      }
    });
  });

  const map = new Map({
    layers: [raster, vector],
    controls: defaultControls().extend([new GoogleLogoControl()]),
    target: 'map',
    view: new View({
      center: coords,
      zoom: zoom,
      minZoom: 4,
      maxZoom: 20
    }),
  });

  map.addInteraction(modify);

  let draw, snap; // global so we can remove them later
  const typeSelect = document.getElementById('type');

  function addInteractions() {
    let value = typeSelect.value;
    let name = '';
    let geometryFunction;
    if (value !== 'none') {
      if (value === 'detection-zone') {

        value = 'Circle';

        geometryFunction = function (coordinates, geometry, projection) {
          if (!geometry) {
            geometry = new GeometryCollection([
              new Polygon([]),
              new Point(coordinates[0]),
            ]);
          }
          geometry.set('name', 'detection');

          const geometries = geometry.getGeometries();
          const center = transform(coordinates[0], projection, 'EPSG:4326');
          const last = transform(coordinates[1], projection, 'EPSG:4326');
          // const radius = getDistance(center, last);
          const radius = 3000;
          const circle = circular(center, radius, 128);
          circle.transform('EPSG:4326', projection);
          geometries[0].setCoordinates(circle.getCoordinates());
          geometry.setGeometries(geometries);
          
          return geometry;
        };
      }
      if (value === 'detection-zone-1-5') {

        value = 'Circle';

        geometryFunction = function (coordinates, geometry, projection) {
          if (!geometry) {
            geometry = new GeometryCollection([
              new Polygon([]),
              new Point(coordinates[0]),
            ]);
          }
          geometry.set('name', 'detection-1-5');

          const geometries = geometry.getGeometries();
          const center = transform(coordinates[0], projection, 'EPSG:4326');
          const last = transform(coordinates[1], projection, 'EPSG:4326');
          // const radius = getDistance(center, last);
          const radius = 1500;
          const circle = circular(center, radius, 128);
          circle.transform('EPSG:4326', projection);
          geometries[0].setCoordinates(circle.getCoordinates());
          geometry.setGeometries(geometries);
          
          return geometry;
        };
      }
      if (value === 'radar-zone') {
        value = 'Circle';

        geometryFunction = function (coordinates, geometry, projection) {
          if (!geometry) {
            geometry = new GeometryCollection([
              new Polygon([]),
              new Point(coordinates[0]),
            ]);
          }
          geometry.set('name', 'radar');

          const geometries = geometry.getGeometries();
          const center = transform(coordinates[0], projection, 'EPSG:4326');
          const last = transform(coordinates[1], projection, 'EPSG:4326');
          const radius = 500;
          const circle = circular(center, radius, 128);
          circle.transform('EPSG:4326', projection);
          geometries[0].setCoordinates(circle.getCoordinates());
          geometry.setGeometries(geometries);
          return geometry;
        };
      }
      if (value === 'suppression-zone') {
        value = 'Circle';

        geometryFunction = function (coordinates, geometry, projection) {
          if (!geometry) {
            geometry = new GeometryCollection([
              new Polygon([]),
              new Point(coordinates[0]),
            ]);
          }
          geometry.set('name', 'suppression');

          const geometries = geometry.getGeometries();
          const center = transform(coordinates[0], projection, 'EPSG:4326');
          const last = transform(coordinates[1], projection, 'EPSG:4326');
          const radius = 200;
          const circle = circular(center, radius, 128);
          circle.transform('EPSG:4326', projection);
          geometries[0].setCoordinates(circle.getCoordinates());
          geometry.setGeometries(geometries);
          
          return geometry;
        };
      }

      if (value === 'detector') {
        value = 'Circle';

        geometryFunction = function (coordinates, geometry, projection) {
          if (!geometry) {
            geometry = new GeometryCollection([
              new Polygon([]),
              new Point(coordinates[0]),
            ]);
          }
          geometry.set('name', 'detector');

          const geometries = geometry.getGeometries();
          const center = transform(coordinates[0], projection, 'EPSG:4326');
          const last = transform(coordinates[1], projection, 'EPSG:4326');
          const radius = 100;
          const circle = circular(center, radius, 128);
          circle.transform('EPSG:4326', projection);
          geometries[0].setCoordinates(circle.getCoordinates());
          geometry.setGeometries(geometries);
          return geometry;
        };
      }

      if (value === 'suppressor') {
        value = 'Circle';

        geometryFunction = function (coordinates, geometry, projection) {
          if (!geometry) {
            geometry = new GeometryCollection([
              new Polygon([]),
              new Point(coordinates[0]),
            ]);
          }
          geometry.set('name', 'suppressor');

          const geometries = geometry.getGeometries();
          const center = transform(coordinates[0], projection, 'EPSG:4326');
          const last = transform(coordinates[1], projection, 'EPSG:4326');
          const radius = 100;
          const circle = circular(center, radius, 128);
          circle.transform('EPSG:4326', projection);
          geometries[0].setCoordinates(circle.getCoordinates());
          geometry.setGeometries(geometries);
          return geometry;
        };
      }

      if (value === 'detection-station') {
        value = 'Circle';

        geometryFunction = function (coordinates, geometry, projection) {
          if (!geometry) {
            geometry = new GeometryCollection([
              new Polygon([]),
              new Point(coordinates[0]),
            ]);
          }
          geometry.set('name', 'detection-station');

          const geometries = geometry.getGeometries();
          const center = transform(coordinates[0], projection, 'EPSG:4326');
          const last = transform(coordinates[1], projection, 'EPSG:4326');
          const radius = 100;
          const circle = circular(center, radius, 128);
          circle.transform('EPSG:4326', projection);
          geometries[0].setCoordinates(circle.getCoordinates());
          geometry.setGeometries(geometries);
          return geometry;
        };
      }

      if (value === 'all-devices') {
        value = 'Circle';

        geometryFunction = function (coordinates, geometry, projection) {
          if (!geometry) {
            geometry = new GeometryCollection([
              new Polygon([]),
              new Point(coordinates[0]),
            ]);
          }
          geometry.set('name', 'all-devices');

          const geometries = geometry.getGeometries();
          const center = transform(coordinates[0], projection, 'EPSG:4326');
          const last = transform(coordinates[1], projection, 'EPSG:4326');
          const radius = 100;
          const circle = circular(center, radius, 128);
          circle.transform('EPSG:4326', projection);
          geometries[0].setCoordinates(circle.getCoordinates());
          geometry.setGeometries(geometries);
          
          return geometry;
        };
      }

      // if (value === 'legend') {
      //   value = 'Circle';
      //   geometryFunction = createBox();

      //     // geometry.set('name', 'legend');

      // }
    

      draw = new Draw({
        source: source,
        type: value,
        name: name,
        geometryFunction: geometryFunction,
      });
      map.addInteraction(draw);
      snap = new Snap({source: source});
      map.addInteraction(snap);
    }
  }

  /**
   * Handle change event.
   */
  typeSelect.onchange = function () {
    map.removeInteraction(draw);
    map.removeInteraction(snap);
    addInteractions();
  };

  // map.getView().on('change:resolution', function(resolution){
  //   console.log(resolution);
  //   // Get the style object of the icon
  //   // var style = anchor.getStyle();
  
  //   // Set the zooming rate of the icon, based on the level 10 to make a zoom
  //   // style.getImage().setScale(this.getZoom() / 10);
  
  //   // anchor.setStyle(style);
  // })

  addInteractions();

  document.getElementById('delete-btn').addEventListener('click', function () {
    let features = vector.getSource().getFeatures();
    console.log('features = ', features);
    features.forEach((feature) => {
        vector.getSource().removeFeature(feature);
    });
  });

  document.getElementById('toggle-history-btn').addEventListener('click', function () {
    historyWindow.classList.toggle('hidden');
  });

  function deleteFeatureByID(ID) {
    let features = vector.getSource().getFeatures();
    features.forEach((feature) => {
      if (feature['ol_uid'] === ID) {
        vector.getSource().removeFeature(feature);
      }
    });
  }

  function refreshFeaturesList(featuresArr) {
    if (featuresArr.length > 0) {
      historyInfo.classList.add('hidden');
    } else {
      historyInfo.classList.remove('hidden');
    }

    featuresList.innerHTML = '';
    let features = vector.getSource().getFeatures();
    let names = {
      'detection': 'Зона обнаружения (3км)',
      'detection-1-5': 'Зона обнаружения (1.5км)',
      'radar': 'Зона радара (500м)',
      'suppression': 'Зона подавления (200м)',
      'detector': 'Обнаружитель',
      'suppressor': 'Подавитель',
      'detection-station': 'Станция траекторного обнаружения',
      'all-devices': 'Все устройства в одной точке',
    };

    for (let i = 0; i < featuresArr.length; i++) {
      let li = document.createElement('li');
      let p = document.createElement('p');
      let a = document.createElement('a');
      let iEl = document.createElement('i');
      let name = '';

      iEl.classList.add('bi');
      iEl.classList.add('bi-x-lg');
      a.href = '#';
      a.append(iEl);

      a.addEventListener('click', function (e) {
        e.preventDefault();
        console.log(e.target.parentElement.parentElement);
        e.target.parentElement.parentElement.remove();
        deleteFeatureByID(featuresArr[i]);
        // let newResultArr = resultArr.filter((deleteID) => deleteID !== featuresArr[i]);
        // let changedArr = featuresArray.filter((deleteID) => deleteID !== featuresArr[i]);
        featuresArray = featuresArray.filter((deleteID) => deleteID !== featuresArr[i]);
        // resultArr.length = 0;
        // resultArr = newResultArr;

        let filteredArr = getFilteredFeaturesArray();
        refreshFeaturesList(filteredArr);

        console.log('featuresArray = ', featuresArray);
        console.log('filteredArr = ', filteredArr);
        // console.log('NEW resultArr = ', resultArr);

      });

      features.forEach((feature) => {
        if (feature['ol_uid'] === featuresArr[i]) {
          // console.log('!!@@ feature = ', feature);
          if (feature.values_.geometry.values_ !== null) {
            if (Object.hasOwn(feature.values_.geometry.values_, 'name')) {
              name = names[feature.values_.geometry.values_.name];
            } 
          } else {
            name = 'Периметр защиты';
          }
        }
      });

      p.innerHTML = name;
      li.append(p);
      li.append(a);
      featuresList.append(li);

    }
  }

//   const items = [
//     {
//         text: 'Center map here',
//         classname: 'bold',
//         // icon: centerIcon,
//         callback: center,
//     },
// ];

// const removeMarkerItem = {
//   text: 'Remove this Feature',
//   classname: 'feature',
//   callback: deleteFeature,
// };

//   let contextmenu = new ContextMenu({
//     width: 170,
//     defaultItems: false,
//     items: [
//       {
//         text: 'Delete',
//         callback: deleteFeature
//       }
//     ]
//   });

//   function elastic(t) {
//     return Math.pow(2, -10 * t) * Math.sin(((t - 0.075) * (2 * Math.PI)) / 0.3) + 1;
//   }

//   function center(obj) {
//     view.animate({
//         duration: 700,
//         easing: elastic,
//         center: obj.coordinate,
//     });
// }

//   map.addControl(contextmenu);

//   // function deleteFeatures(obj){
//   //   // var features = source.getFeatures();
//   //   let features = vector.getSource().getFeatures();
//   //   console.log(features);
//   //   // source.removeFeature(features[0]);
//   //   vector.getSource().removeFeature(obj.feature);
//   //   // map.removeControl(contextmenu);
//   // }

//   function deleteFeature(obj) {
//     console.log(obj);
//     vector.getSource().removeFeature(obj.data.marker);
// }

//   contextmenu.on('open', function (evt) {
//     const feature = map.forEachFeatureAtPixel(evt.pixel, (ft) => ft);

//     if (feature && feature.get('type') === 'removable') {
//         contextmenu.clear();
//         removeMarkerItem.data = { marker: feature };
//         contextmenu.push(removeMarkerItem);
//     } else {
//         contextmenu.clear();
//         contextmenu.extend(items);
//         contextmenu.extend(contextmenu.getDefaultItems());
//     }
//   });

//   map.on('pointermove', function (e) {
//       if (e.dragging) return;

//       const pixel = map.getEventPixel(e.originalEvent);
//       const hit = map.hasFeatureAtPixel(pixel);

//       map.getTargetElement().style.cursor = hit ? 'pointer' : '';
//   });















///////////////////// DELETE FEATURE

//1.
// const pinIcon =
// 'https://cdn.jsdelivr.net/gh/jonataswalker/ol-contextmenu@604befc46d737d814505b5d90fc171932f747043/examples/img/pin_drop.png';
// const centerIcon =
// 'https://cdn.jsdelivr.net/gh/jonataswalker/ol-contextmenu@604befc46d737d814505b5d90fc171932f747043/examples/img/center.png';
// const listIcon =
// 'https://cdn.jsdelivr.net/gh/jonataswalker/ol-contextmenu@604befc46d737d814505b5d90fc171932f747043/examples/img/view_list.png';

// const items = [
//   {
//       text: 'Center map here',
//       classname: 'bold',
//       icon: centerIcon,
//       callback: center,
//   },
// ];

// const contextmenu = new ContextMenu({
//   width: 200,
//   defaultItems: true,
// });

// map.addControl(contextmenu);

// let tempID = 0;

// const removeMarkerItem = {
//   text: 'Remove this Marker',
//   // classname: 'vector',
//   callback: removeMarker,
// };

// contextmenu.on('open', function (evt) {
//   const feature = map.forEachFeatureAtPixel(evt.pixel, (ft) => ft);

//   console.log(feature);
//   console.log(feature.ol_uid);

//   // if (feature && feature.get('type') === 'removable') {
//   if (feature) {
//       contextmenu.clear();
//       removeMarkerItem.data = { feature };
//       tempID = feature.ol_uid;
//       contextmenu.push(removeMarkerItem);
//   } else {
//       contextmenu.clear();
//       contextmenu.extend(items);
//       contextmenu.extend(contextmenu.getDefaultItems());
//   }
// });

// map.on('pointermove', function (e) {
//   if (e.dragging) return;

//   const pixel = map.getEventPixel(e.originalEvent);
//   const hit = map.hasFeatureAtPixel(pixel);

//   map.getTargetElement().style.cursor = hit ? 'pointer' : '';
// });

// function elastic(t) {
//   return Math.pow(2, -10 * t) * Math.sin(((t - 0.075) * (2 * Math.PI)) / 0.3) + 1;
// }

// function center(obj) {
//   view.animate({
//       duration: 700,
//       easing: elastic,
//       center: obj.coordinate,
//   });
// }

// function removeMarker(obj) {
//   // console.log(obj);
//   console.log('obj.data.feature = ', obj.data.feature);
//   // console.log(tempID);

//   // let features = vector.getSource().getFeatures();
//   // console.log(features);

//   // let resultFeature;

//   // for (var i = 0; i < features.length; i++) {
//   //   //  for (let key in features[i]) {
//   //   //     if (features[i][key].indexOf(tempID) !== -1) {
//   //   //       resultFeature = features[i];
//   //   //     }
//   //   //  }

//   //   // console.log('features[i].ol_uid = ', features[i].ol_uid);
//   //   // if (features[i].ol_uid == tempID) {
//   //   //   resultFeature = features[i];
//   //   // }
//   // }

//   // console.log('resultFeature = ', resultFeature);

//   // let feature = vector.getSource().getFeatureById(tempID);
//   // console.log(obj.data.ol_uid);
//   // let feature = obj.getFeatures();
//   vector.getSource().removeFeature(obj.data.feature);

//   // vector.getSource().removeFeature(resultFeature);
//   // tempID = 0;
// }



//2.



///////////////////// DELETE FEATURE END

// let features = vector.getSource().getFeatures();
// console.log('features = ', features);
// features.forEach((feature) => {
//     vector.getSource().removeFeature(feature);
// });



















  document.getElementById('screenshot-btn').addEventListener('click', function () {
    map.once('rendercomplete', function () {
      const mapCanvas = document.createElement('canvas');
      const size = map.getSize();
      mapCanvas.width = size[0];
      mapCanvas.height = size[1];
      const mapContext = mapCanvas.getContext('2d');
      Array.prototype.forEach.call(
        map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
        function (canvas) {
          if (canvas.width > 0) {
            const opacity =
              canvas.parentNode.style.opacity || canvas.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            let matrix;
            const transform = canvas.style.transform;
            if (transform) {
              // Get the transform parameters from the style's transform matrix
              matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(',')
                .map(Number);
            } else {
              matrix = [
                parseFloat(canvas.style.width) / canvas.width,
                0,
                0,
                parseFloat(canvas.style.height) / canvas.height,
                0,
                0,
              ];
            }
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix,
            );
            const backgroundColor = canvas.parentNode.style.backgroundColor;
            if (backgroundColor) {
              mapContext.fillStyle = backgroundColor;
              mapContext.fillRect(0, 0, canvas.width, canvas.height);
            }
            mapContext.drawImage(canvas, 0, 0);
          }
        },
      );
      mapContext.globalAlpha = 1;
      mapContext.setTransform(1, 0, 0, 1, 0, 0);
      const link = document.getElementById('image-download');
      link.href = mapCanvas.toDataURL();
      link.click();
    });
    map.renderSync();
  });

  let mapLink = document.getElementById('map-link');

  map.on("moveend", function() {
    // let center = map.getView().getCenter(); 
    let center = transform(map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326'); 
    // let coordsWebMercatorOnMove = fromLonLat([center[1], center[0]], 'EPSG:4326');
    let zoom = map.getView().getZoom().toFixed(2); 
    let info = 'Увеличение = ' + zoom + ' | Координаты = ' + center[1].toFixed(7) + ',' + center[0].toFixed(7);
    mapLink.innerHTML = info;
    mapLink.href = `/?${center[1].toFixed(7)},${center[0].toFixed(7)},${zoom}z`;
  });

  mapLink.addEventListener('click', (e) => {
    e.stopPropagation();
    e.preventDefault();

    navigator.clipboard.writeText(mapLink.href).then(function() {
      // console.log('Copied!');
      showToastNow('Уведомление', 'success', 'Ссылка на участок карты скопирована в буфер обмена');
      }, function() {
          // console.log('Copy error')
      });
  })

}

createCoordMapBtn.addEventListener('click', (e) => {
  e.preventDefault();

  let xCoord;
  let yCoord;
  let zoom;
  let mapLinkInputValue = document.getElementById('map-link-input').value ? document.getElementById('map-link-input').value : "";
  let mapParameters = [];
  let coordsWebMercator;

  let reg_coord = /^\d([.',]?\d){1,20}(\s*)?$/;
  

  // console.log('mapLinkInputValue = ', mapLinkInputValue);

  if (!mapLinkInputValue) {

    xCoord = document.getElementById('x-coord').value;
    yCoord = document.getElementById('y-coord').value;

    if (xCoord === '' || xCoord === undefined || xCoord === 0 || yCoord === '' || yCoord === undefined || yCoord === 0) {
      xCoord = 57.6061054;
      yCoord = 90.8151635;
      zoom = 4;

      coordsWebMercator = fromLonLat([yCoord, xCoord]);
      showMap(coordsWebMercator, zoom);
    
      formBlock.classList.add('hidden');
      controlContainer.classList.remove('hidden');

    } else {
      if (reg_coord.test(xCoord) && reg_coord.test(yCoord)){
        xCoord = xCoord.replace("'", ".");
        xCoord = xCoord.replace(",", ".");
        yCoord = yCoord.replace("'", ".");
        yCoord = yCoord.replace(",", ".");

        zoom = 13;

        coordsWebMercator = fromLonLat([yCoord, xCoord]);
        showMap(coordsWebMercator, zoom);
      
        formBlock.classList.add('hidden');
        controlContainer.classList.remove('hidden');
      } else {
        showToastNow('Ошибка', 'danger', 'В введённых координатах ошибка или недопустимые символы. Пример координаты:<br/><mark>57.6061054</mark>');
      }
    }

    // console.log(xCoord, yCoord);

  } else {

    // console.log('else mapLinkInputValue = ', mapLinkInputValue);
    if (mapLinkInputValue.includes('google.com')) {
      mapParameters = mapLinkInputValue.slice(mapLinkInputValue.indexOf('@') + 1);
      mapParameters = mapParameters.split(',', 3);

      // console.log('mapParameters = ', mapParameters);

      if (mapParameters.length === 3) {
        if ((mapParameters[0] && mapParameters[0] !== '' && mapParameters[0] !== undefined) && (mapParameters[1] && mapParameters[1] !== '' && mapParameters[1] !== undefined)) {
          // console.log('IF mapParameters = ', mapParameters[0], mapParameters[1]);
          yCoord = mapParameters[1];
          xCoord = mapParameters[0];

          if (!mapParameters[2] || mapParameters[2] == '' || mapParameters[2] == undefined) {
            mapParameters[2] = 13;
          } else {
            mapParameters[2] = mapParameters[2].split('z')[0];
          }

          // console.log('mapParameters + zoom = ', mapParameters);

          zoom = mapParameters[2];
        } 

        coordsWebMercator = fromLonLat([yCoord, xCoord]);
        showMap(coordsWebMercator, zoom);
      
        formBlock.classList.add('hidden');
        controlContainer.classList.remove('hidden');

      } else {
        // console.log('mapParameters.length = ', mapParameters.length);
        showToastNow('Ошибка', 'danger', 'В ссылке не указаны необходимые параметры. Пример ссылки:<br/>https://www.google.com/maps/<mark>@59.0493677,60.5939482,14.25z</mark>');
      }
    } else if (mapLinkInputValue.includes('yandex.ru')) {
      // https://yandex.ru/maps/213/moscow/?ll=37.537721%2C55.661233&mode=search&sll=37.538670%2C55.661453&text=55.661453%2C37.538670&z=16.86
      mapParameters = mapLinkInputValue.slice(mapLinkInputValue.indexOf('?') + 4);
      if (mapParameters.includes('&sll=')) {
        try {
          mapParameters = mapParameters.split('&sll=')[1];
          mapParameters = mapParameters.split('&');
          mapParameters[1] = mapParameters[0].split('%2C')[1];
          mapParameters[0] = mapParameters[0].split('%2C')[0];
          mapParameters[2] = mapParameters[2].split('z=')[1];
        } catch(err) {
          showToastNow('Ошибка', 'danger', err);
        }
      } else {
        try {
          mapParameters = mapParameters.split('%2C');
          mapParameters[2] = mapParameters[1].split('&z=')[1];
          mapParameters[1] = mapParameters[1].split('&z=')[0];
        } catch(err) {
          showToastNow('Ошибка', 'danger', err);
        }
      }

      // console.log('mapParameters = ', mapParameters);

      if (mapParameters.length === 3) {
        if ((mapParameters[0] && mapParameters[0] !== '' && mapParameters[0] !== undefined) && (mapParameters[1] && mapParameters[1] !== '' && mapParameters[1] !== undefined)) {
          // console.log('IF mapParameters = ', mapParameters[0], mapParameters[1]);
          yCoord = mapParameters[0];
          xCoord = mapParameters[1];

          if (!mapParameters[2] || mapParameters[2] == '' || mapParameters[2] == undefined) {
            mapParameters[2] = 13;
          }

          zoom = mapParameters[2];
        } 

        coordsWebMercator = fromLonLat([yCoord, xCoord]);
        showMap(coordsWebMercator, zoom);
      
        formBlock.classList.add('hidden');
        controlContainer.classList.remove('hidden');

      } else {
        // console.log('mapParameters.length = ', mapParameters.length);
        showToastNow('Ошибка', 'danger', 'В ссылке не указаны необходимые параметры. Пример ссылки:<br/>https://yandex.ru/maps/<mark>?ll=30.518550%2C59.905095&z=9.8</mark>');
      }
    } else {
      showToastNow('Ошибка', 'danger', 'Можно использовать только ссылки на яндекс.карты или google maps');
    }
  }

});

if (currentLink.includes('?')) {
  // console.log(currentLink);

  let mapParameters = currentLink.slice(currentLink.indexOf('?') + 1);
  mapParameters = mapParameters.split(',', 3);

  // console.log('mapParameters = ', mapParameters);

  if ((mapParameters[0] && mapParameters[0] !== '' && mapParameters[0] !== undefined) && (mapParameters[1] && mapParameters[1] !== '' && mapParameters[1] !== undefined)) {
    if (!mapParameters[2] || mapParameters[2] == '' || mapParameters[2] == undefined) {
      mapParameters[2] = 13;
    } else {
      mapParameters[2] = mapParameters[2].split('z')[0];
    }

    const coordsWebMercatorFromLink = fromLonLat([mapParameters[1], mapParameters[0]]);
    showMap(coordsWebMercatorFromLink, mapParameters[2]);

    formBlock.classList.add('hidden');
    controlContainer.classList.remove('hidden');
  }
}





